

































import {FirstPage} from "@/request/shop/firstPage";
import el_add_employee_list from '@/components/first-page-components/el-add-employee-list.vue';

export default {
    name: "VisitingCardList",
    components: {
        'el-add-employee-list': el_add_employee_list
    },
    props: {
        id: {
            type: String
        }
    },
    data() {
        let self: any = this;
        return {
            list: []
        }
    },
    created() {
        let self: any = this;
        self.getData();
    },
    methods: {
        getData() {
            let self: any = this;
            FirstPage.getCardListById(self.id).then((body: any) => {
                if (body.code === 0) {
                    self.list = body.data;
                }
            })
        },
        add() {
            let self: any = this;
            self.$refs['add-employee'].show();
        },
        onPopperShow() {
            let self: any = this;
            self.$forceUpdate();
        },
        onAddSubmit(data: any) {
            let self: any = this;
            self.$Message.loading('请稍后');
            FirstPage.saveOrUpdateCard(Object.assign({pageId: self.id}, data)).then((body: any) => {
                if (body.code === 0) {
                    self.$Message.destroy();
                    self.$Message.success('添加成功');
                    self.getData();
                }
            })
        },
        updateSort(row: any) {
            let self: any = this;
            self.$Message.loading('请稍后');
            FirstPage.updateSort(row.id, row.sort).then((body: any) => {
                if (body.code === 0) {
                    self.$Message.destroy();
                    self.getData();
                }
            })
        },
        remove(id: string) {
            let self: any = this;
            self.$Message.loading('请稍后');
            FirstPage.deleteCardById(id).then((body: any) => {
                if (body.code === 0) {
                    self.$Message.destroy();
                    self.getData();
                    self.$parent.$emit('on-remove')
                }
            })
        }
    }
}
