






























import {StoreEmployee} from "@/request/merchant/StoreEmployee";

export default {
    name: "el-add-employee-list",
    data() {
        return {
            addModal: {
                show: false,
            },
            loading: true,
            params: {
                pageIndex: 1,
                pageSize: 5,
                searchStr: ''
            },
            columns: [
                {
                    title: '头像',
                    minWidth: 80,
                    render: (h: any, params: any) => {
                        return h('img', {
                            style: {
                                borderRadius: '3px',
                                height: '40px',
                                width: '40px',
                                marginTop: '5px'
                            },
                            attrs: {
                                src: params.row.image
                            }
                        })
                    }
                },
                {
                    title: '姓名',
                    key: 'name',
                    minWidth: 120
                },
                {
                    title: '手机号',
                    key: 'acc',
                    minWidth: 140
                },
                {
                    title: '操作',
                    width: 160,
                    render: (h: any, p: any) => {
                        let self: any = this;
                        return h('ButtonGroup', [
                            h('Button', {
                                props: {
                                    size: 'small',
                                    type: 'primary'
                                },
                                on: {
                                    click() {
                                        let row = p.row;
                                        self.$emit('on-ok', {
                                            employeeId: row.id,
                                            defaultRecommend: 0
                                        });
                                    }
                                }
                            }, '添加'),
                            h('Button', {
                                props: {
                                    size: 'small',
                                    type: 'success'
                                },
                                on: {
                                    click() {
                                        let row = p.row;
                                        self.$emit('on-ok', {
                                            employeeId: row.id,
                                            defaultRecommend: 1
                                        });
                                    }
                                }
                            }, '设为默认')
                        ])
                    }
                }
            ],
            resData: {}
        }
    },
    created() {
        let self: any = this;
        self.params.pageIndex = 1;
        self.params.searchStr = '';
        self.getData();
    },
    methods: {
        show() {
            let self: any = this;
            self.addModal.show = true;
        },
        getData() {
            let self: any = this;
            self.loading = true;
            StoreEmployee.getSummaryList(self.params).then((body: any) => {
                self.loading = false;
                if (body.code === 0) {
                    self.resData = body.data;
                }
            })
        },
        onSearch(query: any) {
            let self: any = this;
            self.params.pageIndex = 1;
            this.getData();
        },
        changePage(pageIndex: number) {
            let self: any = this;
            self.params['pageIndex'] = pageIndex;
            self.getData();
        }
    }
}
